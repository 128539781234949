<template>
  <div id="login-page">
    <div class="login-container">
      <div class="login-container__form">
        <div class="header-login">
          <h5 class="ml-3 font-weight-light">Only accessible with Login</h5>
        </div>

        <form @submit.prevent="login">
          <div class="login-container__form-inner">
            <b-form-group>
              <label for="email"> Username </label>
              <b-form-input
                id="email"
                v-model="form.user"
                size="sm"
                type="text"
                placeholder="Enter your email"
                :disabled="loading"
              />
            </b-form-group>

            <b-form-group>
              <label for="email"> Password </label>
              <b-form-input
                id="password"
                v-model="form.pass"
                size="sm"
                type="password"
                placeholder="Enter your password"
                :disabled="loading"
              />
            </b-form-group>

            <div class="buttons mb-4">
              <button
                class="btn btn-sm btn-pompe-primary login-button mt-2"
                :loading="loading"
                :disabled="loading"
                @click.prevent="login"
              >
                <span v-if="!loading"> Sign In </span>
                <span v-else>
                  <b-spinner small label="Small Spinner"></b-spinner>
                </span>
              </button>
            </div>

            <div class="links">
              <div class="forgot-password">
                Forgot Password? <br />
                <router-link :to="{ name: 'RequestPassword' }" class="anchor-link">
                  Reset Password
                </router-link>
              </div>

              <div class="no-account">
                No Account? <br />
                <a href="javascript:void(0)" class="anchor-link">
                  For getting a login, please contact administrator
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <p style="font-size: 13px; text-align:justify">
      The Swiss Pompe Registry is a strictly observational and anonymous
      electronic registry of Swiss patients suffering from late onset Pompe
      disease. The registry was a compulsory condition for reimbursement of
      Myozyme in late onset Pompe disease from the Federal office of Public
      Health (FoPH):
      <a class="anchor-link" href="http://www.spezialitätenliste.ch" target="_blank">
        http://www.spezialitätenliste.ch</a
      >
    </p>

    <p style="font-size: 13px; text-align:justify">
      It was designed and introduced in November 2011 in close collaboration
      with the Swiss experts in Pompe disease as well as the Federal office of
      Public Health (FoPH). The development and operation are funded by Sanofi:
      <a class="anchor-link" href="https://www.sanofi.ch" target="_blank">https:/www.sanofi.ch</a>
    </p>

    <p style="font-size: 13px; text-align:justify">
      The Swiss Pompe experts and Federal office of Public Health (FoPH) have
      designed the registry to manage and standardize patient care and clinical
      monitoring for late onset Pompe disease based on international treatment
      guidelines. This includes defined parameters and time periods between two
      assessments. The content of the Swiss Pompe Registry is managed according
      to current disease knowledge and may change in the light of new insights.
      The collected data is used to evaluate the effectiveness of pompe treatment in Swiss pompe patients.
    </p>
      
    <p style="font-size: 13px; text-align:justify">
      A written consent from the patient or his/her
      legal guardian to perform the necessary periodic assessments and to
      register and archive the data compiled as a result of the treatment in the
      Swiss Pompe Register is mandatory.
    </p>

    <br />
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      form: {
        user: "",
        pass: "",
      },
      loading: false,
    };
  },
  mounted() {
    const token = this.$cookies.get("token");
    if (token) location.href = "/web";
  },
  methods: {
    login() {
      const queryString = require("query-string");
      this.loading = true;

      this.$requestService
        .auth(
          this.$apiEndpoints.login,
          queryString.stringify(this.form),
          this.handleLoginSuccess,
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleLoginSuccess(response) {
      this.$cookies.set("token", response.data.token, 0);
      this.$cookies.set("refreshToken", response.data.refreshToken, 0);

      sessionStorage.setItem("token", response.data.token);
      location.href = "/web";
    },
    handleFailed(e) {
      this.$vToastify.error(e.response.data.errors[0].message || 'Please contact administrator');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";

.login-container {
  padding-top: 3em;
  padding-bottom: 2.5em;

  .header-login {
    background-color: $primary-ui;
    color: #fff;
    height: 50px;
    padding: 0.75em 0 1em 0.5em;
  }

  &__form {
    display: center;
    justify-content: start;
    align-content: left;
    border: 1px solid #e4e4e4;
    // box-shadow: 2px 2px 5px #c9c9c9;
    width: 600px;
    margin: auto;
    // background-color: #E4E4E4;

    &-inner {
      width: 400px;
      padding-left: 1.5em;
      padding-top: 1em;
    }

    .login-button {
      width: 200px;
    }

    .links {
      font-size: 13px;
      padding-bottom: 20px;

      .no-account {
        margin-top: 1em;
      }
    }
  }
}
</style>
